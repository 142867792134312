import { render, staticRenderFns } from "./Sidemenuscroll.vue?vue&type=template&id=f66fa08e&scoped=true&lang=pug&"
import script from "./Sidemenuscroll.vue?vue&type=script&lang=ts&"
export * from "./Sidemenuscroll.vue?vue&type=script&lang=ts&"
import style0 from "./Sidemenuscroll.vue?vue&type=style&index=0&id=f66fa08e&prod&lang=scss&"
import style1 from "./Sidemenuscroll.vue?vue&type=style&index=1&id=f66fa08e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f66fa08e",
  null
  
)

export default component.exports